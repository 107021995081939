/**
 * Created by Tomasz Gabrysiak @ Infermedica on 06/03/2017.
 *
 * Please provide your own app-id and app-key below.
 * To obtain keys please register at https://developer.infermedica.com.
 */

const settings = {
  'app-id': 'b8c029c5',
  'app-key': 'fc679b664bd979bb4f29b9d7a9f626ab'
};

export default settings;
