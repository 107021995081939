/**
 * Created by Tomasz Gabrysiak @ Infermedica on 08/02/2017.
 */

import html from '../../templates/helpers';

const conditionsHtmlMapper = (conditions) => {
  return conditions.map((condition) => `
    <div class="summary-item row">
      <div class="col-8">
        ${condition.name}
        ${condition.probability >= 0.2
    ? `<i class="fa fa-fw fa-eye"></i><a href data-id="${condition.id}" class="explain">explain</a>` : ''}
      </div>
      <div class="col-4">
        <div class="progress">
          <div class="progress-bar bg-info" role="progressbar" 
              style="width: ${Math.floor(condition.probability * 100)}%">
            ${Math.floor(condition.probability * 100)}%
          </div>
        </div>
      </div>
      <div class="explanation col-24"></div>
    </div>          
  `);
};

const template = (context) => {
  return context.api.diagnosis(context.patient.toDiagnosis()).then((data) => {
    return html`
      <h5 class="card-title">Summary</h5>
      <div class="card-text">
        <p>Based on the interview, you could suffer from:</p>
        ${conditionsHtmlMapper(data.conditions)}
        <div class="alert alert-warning" role="alert">
        <i class="fa fa-info-circle"></i>
        Please note that the list below may not be complete and is provided solely for informational purposes 
        and is not a qualified medical opinion.
      </div>
        <div class="alert alert-success" role="alert">
          <i class="fa fa-info-circle"></i>
          For a more accurate opinion please consult a doctor at 
          <a target="_blank" href="http://www.docto.com.au/consult-dr-now">docto.com.au </a>
        </div>
      </div>
    `;
  });
};

export default template;
